import React, { lazy, Suspense } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import Loading from "./components/Loading";

import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider
} from "@apollo/client";

const client = new ApolloClient({
    // uri: "https://o8j8tz22v0.execute-api.us-east-1.amazonaws.com/Development/graphql",
    cache: new InMemoryCache()
});

const Home = lazy(() => import('./views/Home'));
const Login = lazy(() => import('./views/LogIn'));
const Profile = lazy(() => import('./views/Profile'));
// const ExternalApi = lazy(() => import('./views/ExternalApi'));
const PageNotFound = lazy(() => import('./views/PageNotFound'));

/*
1) React: http GET API Gateway Enpoint
2) AWS Lambda: Fetch DynamoDB data
5) AWS Lambda: Convert DynamoDB response into GQL Query
6) React consumes the query
*/

const Template = () => {
    const {
        isAuthenticated
    } = useAuth0();


    return (
        <Router history={history}>

            {!isAuthenticated && (
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </Suspense>
            )}
            {isAuthenticated && (
                <ApolloProvider client={client}>
                    <Suspense fallback={<Loading />}>
                        <Switch>
                            {/* <Route path="/external-api" component={ExternalApi} /> */}
                            <Route path="/profile" exact component={Profile} />
                            <Route path="/" exact component={Home} />
                            <Route path="/*" component={PageNotFound} />
                        </Switch>
                    </Suspense>
                </ApolloProvider>
            )
            }
        </Router >
    );
}

export default Template;