import React from "react";

import Loading from "./components/Loading";
import { useAuth0 } from "@auth0/auth0-react";

import Template from "./Template";

const App = () => {
  const { isLoading, error } = useAuth0();
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Template />
  );
};

export default App;
